.cursor-pointer {
  cursor: pointer;
 }
 .bg-hover-effect{
   cursor: pointer;
   border-radius: 5px;
 }
 .bg-hover-effect:hover{
   background-color: #f5f5f5;
 }
 
 .spinner-container{
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #00000030;
   z-index: 100;
   border-radius: 10px;
 }
 
 .spinner-dashboard{
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .w-15{
   width: 15%;
 }
 
 @mixin switch($res: 'sm') {
   $index: 1rem;
   $mainVal: 1rem;
 
   @if $res == 'md' {
       $index: 2rem;
       $mainVal: 1.5rem;
   } @else if $res == 'lg' {
       $index: 3rem;
       $mainVal: 2rem;
   } @else if $res == 'xl' {
       $index: 4rem;
       $mainVal: 2.5rem;
   }
 
   .custom-control-label {
       padding-left: #{$index};
       padding-bottom: #{$mainVal};
   }
 
   .custom-control-label::before {
       height: $mainVal;
       width: calc(#{$index} + 0.75rem);
       border-radius: $mainVal * 2;
   }
 
   .custom-control-label::after {
       width: calc(#{$mainVal} - 4px);
       height: calc(#{$mainVal} - 4px);
       border-radius: calc(#{$index} - (#{$mainVal} / 2));
   }
 
   .custom-control-input:checked ~ .custom-control-label::after {
       transform: translateX(calc(#{$mainVal} - 0.25rem));
   }
 }
 
 // YOU CAN PUT ALL RESOLUTION HERE
 // sm - DEFAULT, md, lg, xl
 .custom-switch.custom-switch-sm {
   @include switch();
 }
 
 .custom-switch.custom-switch-md {
   @include switch('md');
 }
 
 .custom-switch.custom-switch-lg {
   @include switch('lg');
 }
 
 .custom-switch.custom-switch-xl {
   @include switch('xl');
 }

 .fc-event, .fc-event-dot {
  background-color: transparent;
}
.fc{
  height: 600px;
  width: 600px;
}

@media only screen and (min-width: 993px) {
  .hide-mob{
    display: block;
  }
  
  .hide-desk{
    display: none;
  }
}
@media only screen and (max-width: 994px) {
  .hide-mob{
    display: none;
  }
  
  .hide-desk{
    display: block;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.react-datepicker-ignore-onclickoutside{
  width: 100% !important;
}

#google_translate_element{
  z-index: 9999;
  position: fixed;
  right: 100px;
  top: 10px;
}

.font-bold{
  font-weight: bold;
  color: black;
}

.config-container{
  width: 100%;
  height: 60vh;
  position: relative;
}

.config-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 50%;
  background-color: #ececec;
  border-radius: 5px;
  padding: 20px;
}

.css-1uvydh2 {
  padding: 8.5px 14px !important;
}
.css-165mck1{
  width: 100%;
}