.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 3px;
  height: 35px !important;
  margin: 1px 0;
  width: 100%;
  padding: 2px 5px;
}
.rmdp-container{
  display: block !important;
}
.fc .fc-col-header-cell-cushion{
  color: black;
}
.fc .fc-daygrid-day-number{
  color: black;
}
.fc-daygrid-dot-event .fc-event-title{
  color: black;
}
.rs-picker-toggle-wrapper{
  display: block !important;
}